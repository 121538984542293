<template>
  <ExtendedView :tabs="tabs">
    <template slot="list">
      <ActionList
        empty-text="No devices found"
        :load-items-callback="getDevices"
      >
        <template v-slot:item="{ item, index }">
          <DeviceListItem
            v-if="item !== null"
            :key="item.uid"
            :device="item"
            @selectDevice="selectDevice"
          />
          <v-divider
            inset
            :key="index"
          />
        </template>
      </ActionList>
    </template>
    <template slot="profile">
      <DeviceProfile :device="selectedDevice" />
    </template>
  </ExtendedView>
</template>

<script>
  import ExtendedView from "@/components/common/ExtendedView"
  import ActionList from "@/components/common/list/ActionList"
  import DeviceListItem from "@/components/device/DeviceListItem"
  import DeviceProfile from "@/components/device/DeviceProfile"

  export default {
    name: 'Devices',
    components: {
      ExtendedView,
      ActionList,
      DeviceListItem,
      DeviceProfile
    },
    data: () => ({
      selectedDevice: null,
    }),
    computed: {
      tabs() {
        return [
          {
            title: this.$t('all'),
            key: 'all',
            badge: {
              color: 'green',
              content: 0
            }
          },
          {
            title: this.$t('tablets'),
            key: 'tablets',
            badge: {
              color: 'red',
              content: 0
            }
          },
           {
            title: this.$t('printers'),
            key: 'printers',
            badge: {
              color: 'red',
              content: 0
            }
          }
        ]
      },
    },
    methods: {
      selectDevice(device) {
        this.selectedDevice = device
      },
      getDevices() {
        return this.$store.dispatch('getDevices')
      }
    },
    mounted() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
    }
  }
</script>
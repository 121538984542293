<template>
  <v-list-item @click="$emit('selectDevice', device)">
    <v-list-item-avatar>
      <v-icon v-if="device.namespace === 'tablet'">
        mdi-tablet
      </v-icon>
      <v-icon v-if="device.namespace === 'printer'">
        mdi-printer
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>{{ device.id }} {{ device.appVersion }}</v-list-item-title>
      <v-list-item-subtitle>{{ device.lastSeenInMillis | moment }} {{ ipAddress }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
    name: 'DeviceListItem',
    props: ['device'],
    computed: {
      ipAddress() {

        if(this.device.deviceLog === null) {
          return ''
        }

        return this.device.deviceLog.IpAddress
      }
    }
}
</script>
